const phonePrefixMap = {
    //mobifone
    '0120': '070',
    '0121': '079',
    '0122': '077',
    '0126': '076',
    '0128': '078',
    //vinaphone,
    '0123': '083',
    '0124': '084',
    '0125': '085',
    '0127': '081',
    '0129': '082',
    //viettel
    '0162': '032',
    '0163': '033',
    '0164': '034',
    '0165': '035',
    '0166': '036',
    '0167': '037',
    '0168': '038',
    '0169': '039',
    //vietnammobile
    '0186': '056',
    '0188': '058',
    //gmobile
    '0199': '059'
}

const convertPhonePrefix = phone => {
    if (phone.length == 11) {
        const prefix = phone.substring(0, 4);
        const newPhone = phonePrefixMap[prefix] ? phonePrefixMap[prefix] + phone.slice(4, phone.length) : phone;
        return newPhone;
    } else return phone
}

export default {
    convertPhonePrefix
}