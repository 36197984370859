<template>
    <b-modal :active.sync="isActive" :width="640" scroll="keep" :canCancel="['escape', 'outside']">
      <component :bookingId="selectedBookingId" :is="modalComponent"></component>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "BookingModal",
    props: ["type", "modalComponent"],
    computed: {
        ...mapGetters(['selectedBookingId', 'isModalActive']),
        isActive: {
            get() {
                return this.isModalActive[this.type];
            },
            set(value) {
                this.$store.commit("setModalActive", { type: this.type, isActive: value });
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>
