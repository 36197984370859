<template>
    <div class="wrapper is-wrapped has-background-white">
        <h2 class="title is-4 is-uppercase">Ghi chú của khách hàng</h2>
        <section>
            <b-field horizontal label="Số điện thoại">
                <b-input name="subject" expanded disabled v-model="booking.customerPhone"></b-input>
            </b-field>
            <b-field horizontal label="Ghi chú của KH" v-if="!!guessNote">
                <b-input type="textarea" disabled v-model="guessNote"></b-input>
            </b-field>
            <b-field horizontal label="Ghi chú của KH qua hệ thống" v-if="!!systemNote">
                <b-input type="textarea" disabled v-model="systemNote"></b-input>
            </b-field>
            <b-field horizontal label="Ghi chú của Salon">
                <b-input type="textarea" v-model="salonNote" :disabled="isDisabled"></b-input>
            </b-field>

            <b-field horizontal><!-- Label left empty for spacing -->
                <p class="control buttons">
                    <button class="button is-primary" v-bind:class="{'is-static': isDisabled}" @click="updateSalonNote()">
                        Gửi
                    </button>
                </p>
            </b-field>
        </section>
        <ModalCloseButton />
    </div>
</template>

<script>
    import apiConfig from "@/configs/api";
    import axios from "axios";

    import ModalCloseButton from '@/components/Shared/ModalCloseButton';

    export default {
        name: "note",

        components: {
            ModalCloseButton
        },

        props: {
            bookingId: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                salonNote: '',
                guessNote: '',
                systemNote: '',
                isDisabled: ''
            }
        },

        methods: {
            async updateSalonNote() {
                let data = {
                    salonNote: this.salonNote
                }
                await axios.put(`${apiConfig.MAIN_API}/api/booking/update-salon-note?bookingId=${this.booking.id}`, data);
                this.$emit('finish-update', this.salonNote);
                this.$buefy.toast.open('Cập nhập thành công!');
                this.$parent.close();
            }
        },

        computed: {
            booking() {
                return this.$store.getters.bookingMap[this.bookingId];
            }
        },

        created() {
            this.salonNote = this.booking.salonNote;
            this.guessNote = this.booking.note;
            this.systemNote = this.booking.note1;
            this.isDisabled = !!this.booking.salonNote;
        }
    }
</script>

<style scoped>
.wrapper {
    padding: 2rem;
    text-align: center;
}
</style>
