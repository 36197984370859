<template>
    <div>
        <BookingModal :modalComponent="components.BookForm" type="bookForm"/>
        <BookingModal :modalComponent="components.PrintBill" type="printBill"/>
        <BookingModal :modalComponent="components.SalonNote" type="bookNote"/>
        <BookingModal :modalComponent="components.DeleteBookingReason" type="deleteBookingReason"/>
        <OrganizeSkinnerModal />
        
    </div>
</template>

<script>
import SalonNote from "@/components/Timeline/SalonNote";
import PrintBill from "@/components/Timeline/PrintBill";
import BookForm from "@/components/Timeline/BookForm";
import DeleteBookingReason from "@/components/Timeline/DeleteBookingReason";

import BookingModal from "@/components/Timeline/BookingModal";
// import OrganizeSkinnerModal from "@/components/Timeline/OrganizeSkinnerModal";
// const OrganizeSkinnerModal = () => import('@/components/Timeline/OrganizeSkinnerModal')

export default {
    name: "ModalContainer",
    components: {
        BookingModal,
        OrganizeSkinnerModal: () => import('@/components/Timeline/OrganizeSkinnerModal')
    },
    data() {
        return {
            components: {
                SalonNote,
                PrintBill,
                BookForm,
                DeleteBookingReason
            },
        }
    },
    created() {
        console.log('modal container created');
    }
}
</script>

<style>

</style>
