<template>
    <div class="wrapper is-wrapped has-background-white">
        <h2 class="title is-4 is-uppercase">Hủy đặt lịch cho khách</h2>
        
        <section v-if="booking">
            <b-field>Bạn có chắc chắn muốn <b>hủy</b> đặt lịch cho anh {{ booking.customerName}}?</b-field>
            <div class="reasons"> 
                <div class="field" v-for="reason in deleteBookingReasons" :key="reason.id">
                    <b-radio v-model="chosenReasonId" :native-value="reason.id">
                        {{ reason.value }}
                    </b-radio>
                </div>
                <b-field :style="{width: '100%'}">
                    <b-input v-if="chosenReasonId==0" v-model="inputReason" placeholder="Nhập lý do"></b-input>
                </b-field>
            </div>

            <b-field horizontal>
                <p class="control buttons">
                    <button :disabled="chosenReasonId == null || (chosenReasonId == 0 && !inputReason)" class="button is-primary" @click="deleteBooking()">
                        Đồng ý
                    </button>
                    <button class="button is-primary" @click="$parent.close()">
                        Hủy
                    </button>
                </p>
            </b-field>
        </section>
        <ModalCloseButton />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ModalCloseButton from '@/components/Shared/ModalCloseButton';

import apiConfig from "@/configs/api";
import axios from "axios";

export default {
    components: {
        ModalCloseButton
    },

    props: ['bookingId'],

    data() {
        return {
            inputReason: '',
            chosenReasonId: null
        }
    },
    methods: {
        async deleteBooking() {
            this.$store.dispatch("removeBooking", { id: this.bookingId });
            const chosenReason = this.deleteBookingReasons.find(item => item.id == this.chosenReasonId);
            const deleteNote = `${chosenReason.id}: ${chosenReason.value} ${chosenReason.id == 0 ? this.inputReason : ''}`;
            // await axios.delete(`${apiConfig.MAIN_API}/api/booking?bookingId=${this.bookingId}&delNote=${deleteNote}`)
            await axios.delete(`${apiConfig.MAIN_API}/api/booking/delete-v2?bookingId=${this.bookingId}&delNote=${deleteNote}&delReasonId=${chosenReason.id}&userId=${this.user.userId}`);
            this.$buefy.toast.open("Hủy thành công!");
            this.$parent.close()
        },
    },

    computed: {
        ...mapGetters(['deleteBookingReasons', 'user']),
        booking() {
            return this.$store.getters.bookingMap[this.bookingId];
        }
    },

    created() {
        if (!this.deleteBookingReasons.length) this.$store.dispatch('setDeleteBookingReasons');
    }
}
</script>

<style scoped>
.wrapper {
    padding: 2rem;
    text-align: center;
}

.reasons {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 20px;
}
</style>
