<template>
  <div class="has-background-white wrapper book-form">
    <h2 class="title is-2 no-margin is-bold">{{hour.hour}}</h2>
    <p class="margin-2 stylist-text">STYLIST | {{stylist.fullName}}</p>
    <div class="columns">
      <div class="column">
        <CustomerForm v-model="tempCustomer" @customer="getCustomer"></CustomerForm>
      </div>
      <div class="column">
        <div class="field has-text-left">
          <b-checkbox v-model="isBookStylist">Khách book Stylist</b-checkbox>
        </div>
        <div class="field">
          <button
            class="button is-large is-fullwidth is-primary is-uppercase"
            @click="book()"
            :disabled="tempCustomer.error && (tempCustomer.error.phone || tempCustomer.error.name)"
          >Đặt chỗ</button>
        </div>
      </div>
    </div>
    <notifications group="foo" position="bottom right"/>
    <ModalCloseButton />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CustomerForm from "@/components/Timeline/CustomerForm";
import ModalCloseButton from "@/components/Shared/ModalCloseButton";
import axios from "axios";

import apiConfig from "@/configs/api";
import notiUtil from "@/utils/noti";
import convertUtil from "@/utils/convert";

export default {
  name: "BookForm",

  components: {
    CustomerForm,
    ModalCloseButton
  },
  data() {
    return {
      radio: "",
      isBookStylist: false,
      customer: null,
      tempCustomer: {
        name: "",
        phone: "",
        error: {
          name: null,
          phone: null
        }
      },
      customerFormError: null
    };
  },

  props: ['bookingId'],

  methods: {
    async book() {
      try {
        if (this.isEditingCustomer) {
          this.$buefy.snackbar.open({
            message: `Vui lòng lưu thông tin khách hàng trước khi thao tác.`,
            position: 'is-top'
          })
          return;
        }

        let oldCustomer = this.checkCustomer();

        let inputData = {
          customerPhone: this.customer.phone,
          salonId: this.selectedSalon.id,
          stylistId: this.stylist.id,
          hourId: this.hour.hourId,
          subHourId: this.hour.subHourId,
          isBookStylist: this.isBookStylist,
          dateBook: this.$store.getters.selectedMomentDate.format('YYYY-MM-DD')
        };
        // return subHourId and secondSubHourId if subHourId contains secondSubHourId
        inputData = convertUtil.transformSubHourOut(inputData)

        let booking = null;
        if (oldCustomer && !oldCustomer.completeBillTime) {
          if (oldCustomer.isMakeBill && this.$store.getters.getHourIdFromSubHour(oldCustomer.subHourId) !== this.hour.hourId) {
            notiUtil.changeBookingDenyBillPrinted(this, oldCustomer.customerName);
            booking = null;
          } else {
            booking = await this.changeBookingTime(oldCustomer.id, oldCustomer.customerName, inputData);
          }
        } else {
          const { data } = await axios.post(`${apiConfig.MAIN_API}/api/booking`, inputData);
          if (data.message == "update") {
            booking = await this.changeBookingTime(data.booking.bookingId, data.booking.customerName, inputData);
          } else {
            booking = data;
            notiUtil.bookingSuccess(this, booking.customerName);

            if (!this.isTodaySelected)
              this.$store.dispatch("addBooking", booking);
          }
        }
        this.$parent.close();
        return booking;
        
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.message) error = error.response.data.message;
        notiUtil.generalError(this, error);
      }
    },

    async changeBookingTime(bookingId, customerName, inputData) {
      console.log(customerName);
      if (await notiUtil.confirmChangeBookingTime(this, customerName)) {
        const { data: booking } = await axios.put(`${apiConfig.MAIN_API}/api/booking?bookingId=${bookingId}`, inputData);
        console.log('booking after update', booking);
        notiUtil.changeBookingSuccess(this, customerName);

        if (!this.isTodaySelected)
          this.$store.dispatch("addBooking", booking);

        return booking;
      }
      return null;
    },

    checkCustomer() {
      let bookings = this.$store.state.booking.bookings;
      for (let i = 0; i < bookings.length; i++) {
        if (bookings[i].customerPhone === this.customer.phone) {
          return bookings[i];
        }
      }
      return false;
    },

    getCustomer(customer) {
      this.customer = customer;
      this.tempCustomer = customer;
    }
  },

  watch: {},

  computed: {
    ...mapGetters(['isTodaySelected', 'selectedSalon', 'isEditingCustomer']),
    booking() {
      console.log('bookingid', this.bookingId)
      if (typeof this.bookingId == 'string') return {
        stylistId: parseInt(this.bookingId.split('-')[0]),
        subHourId: this.bookingId.split('-')[1]
      }
      return this.$store.getters.bookingMap[this.bookingId];
    },
    stylist() {
      return this.$store.getters.getDetailStylist(this.booking.stylistId)
    },

    hour() {
      console.log('book form with subhourid', this.booking.subHourId)
      return this.$store.getters.getDetailHour(this.booking.subHourId);
    },
    
  }
};
</script>

<style scoped>
.book-form {
  padding: 4rem 2rem;
  text-align: center;
}

.stylist-text {
  font-size: 18px;
}
</style>
