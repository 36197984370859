<template>
    <span class="close-button" @click="closeModal">
      <b-icon icon="close" style="width: 10px; height: 10px;"></b-icon>
    </span>
</template>

<script>
export default {
  name: "ModalCloseButton",
  props: ['isTopLevel'],
  methods: {
    closeModal() {
      if (this.isTopLevel) this.$parent.close()
      else this.$parent.$parent.close()
    }
  }
}
</script>

<style scoped>
.close-button {
    position: absolute;
    right: 10px;
    top: 7px;
    opacity: 1;
    cursor: pointer;
}
</style>
