<template>
  <div class="has-text-left">
    <h4 class="has-text-left is-bold">Khách hàng</h4>
    <b-field :type="{ 'is-danger': error.phone }" :message="error.phone" v-cloak>
      <input
        placeholder="Số điện thoại"
        class="input"
        v-model="customer.phone"
        @blur="getCustomer"
        @keydown.enter="getCustomer"
        v-autofocus
      >
    </b-field>
    <b-field >
      <input
        placeholder="Tên"
        class="input"
        v-model="customer.name"
        @blur="checkValidName"
        @keydown.enter="update"
        :disabled="!isEditing"
      >
      <p class="control" v-if="isEditing">
        <button class="button is-primary" @click="update">Lưu</button>
      </p>
      <p class="control" v-if="!isEditing">
        <button class="button is-primary" @click="isEditing = true">Sửa</button>
      </p>
    </b-field>
    <p class="help is-danger" v-if="error.name">{{error.name}}</p>
  </div>
</template>

<script>
import phoneUtil from "@/utils/phone";
import apiConfig from "@/configs/api";
import axios from "axios";

import validationUtil from '@/utils/validation';

export default {
  name: "CustomerForm",
  props: ["value", "booking"],
  data() {
    return {
      customer: this.value,
      error: {
        name: null,
        phone: null
      },
      customerInfo: null
    };
  },

  methods: {
    checkValidName() {
      if (this.customer.name) {
        this.customer.name = this.customer.name.trim().replace(/\s\s+/g, ' ');
        this.error.name = validationUtil.checkValidNameOnInput(this.customer.name);
      }
    },

    async getCustomer() {
      this.customer.phone = phoneUtil.convertPhonePrefix(this.customer.phone);
      if (this.customer.phone.length !== 10) {
        this.error.phone = "Số điện thoại phải là số có 10 chữ số!";
        return;
      }
      
      let customer;
      const savedCustomer = this.$store.getters.getCustomerByPhone(this.customer.phone);
     
      if (!savedCustomer) {
        const { data } = await axios.get(`${apiConfig.MAIN_API}/api/customer?phone=${this.customer.phone}`);
        if (data) {
          customer = data;
          this.$store.dispatch('setCustomer', { customer, customerPhone: this.customer.phone })
        }
      } else customer = savedCustomer;

      
      this.customer.name = customer ? customer.fullName : '';
      if (!this.customer.name) {
        this.isEditing = true;
        this.$buefy.snackbar.open({
          message: `Khách hàng mới. Vui lòng hỏi tên khách hàng và lưu lại trong hệ thống.`,
          position: 'is-top',
          duration: 1000
        })
      }
      
      this.$emit("customer", {
        name: this.customer.name,
        phone: this.customer.phone
      });
      this.customerInfo = customer;
      if (this.customer.name.length) this.checkValidName();
    },

    async update() {
      let customer = null;
      if (this.customerInfo){
        this.customerInfo.fullName = this.customer.name;
        customer = this.customerInfo;
        await axios.put(`${apiConfig.MAIN_API}/api/customer?customerId=${this.customerInfo.id}&customerName=${this.customer.name}`);
        
        
      } else {
        
        let customerData = {
          fullName: this.customer.name,
          phone: this.customer.phone
        };
        let { data } = await axios.post(`${apiConfig.MAIN_API}/api/customer`, customerData);
        customer = data;
      }

      if (this.booking && this.booking.id) {
        let { data } = await axios.put(`${apiConfig.MAIN_API}/api/booking/update-customer-name?bookingId=${this.booking.id}&customerName=${this.customer.name}`);
      }
      this.customerInfo = customer;
      this.isEditing = false;
      this.$buefy.snackbar.open({
        message: `Thay đổi tên khách hàng thành công.`,
        position: 'is-top'
      })
      this.$emit("customer", customer);
    },
    
  },

  watch: {
    "customer.phone": function(value) {
      this.customer.phone = this.customer.phone.trim().replace(/\s\s+/g, '');
      this.error.phone = validationUtil.checkValidPhoneOnInput(value);

      // if(!this.error.phone && this.customer.phone.length == 10) this.getCustomer();
    },
    "customer.name": function(value) {
      // if (!value.length) return;
      this.error.name = validationUtil.checkValidNameOnInput(value);
    }
  },

  updated() {
    this.$emit("input", {
      name: this.customer.name,
      phone: this.customer.phone,
      error: this.error
    });
  },

  created() {
    this.isEditing = false;
  },

  computed: {
    isEditing: {
      get() {
          return this.$store.getters.isEditingCustomer;
      },
      set(value) {
          this.$store.commit("setEditingCustomer", value);
      }
    }
  }
};
</script>

<style scoped>
.notices {
  z-index: 100000;
}

.has-text-left ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #616161
}

.input {
  color: #000;
}
</style>
