const nameReg = /^[a-z0-9A-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý ]+$/;

const checkValidNameOnInput = value => {
    let error = null;
    if (value.length > 50) error = 'Tên khách tối đa 50 ký tự';
    if (!nameReg.test(value.normalize('NFC'))) error = 'Tên không hợp lệ';
    return error;
}

const checkValidPhoneOnInput = value => {
    let error = null;

    if (!value.startsWith('0')) error = "Số điện thoại phải bắt đầu bằng số 0!";

    if (isNaN(value)) error = "Số điện thoại phải là chữ số!";

    return error;
}

export default {
    checkValidNameOnInput,
    checkValidPhoneOnInput
}