<template>
  <div class="has-background-white wrapper print-bill has-text-left">
    <div class="margin-2">
      <h2 class="title is-3 no-margin has-text-centered">{{hour.hour}}</h2>
      <p class="has-text-centered stylist-text">STYLIST | {{stylist.fullName}}</p>
    </div>
    <div class="columns">
      <div class="column">
        <CustomerForm
          v-model="tempCustomer"
          @customer="getCustomer"
          :booking="booking"
        ></CustomerForm>
        <div class="field" :style="{ marginTop: '10px'}">
          <b-checkbox v-model="bill.isBookStylist">Khách book Stylist</b-checkbox>
        </div>
        <template class="customer-tag">
          <b-message
            :closable="false"
            active
            type="is-warning"
            title="Tags dành cho khách hàng"
            aria-close-label="Close message"
            style="width:275px;"
            v-if="customerTagData.length"
          >
          <span> 
            Tags :
          </span>
            <ul>
              <li
                v-for="item in customerTagData"
                :key="item.customerId"
                style="width: 230px; white-space: normal;
                 word-break: break-all;"
              >{{ '- ' + item.customerTag }}</li>
            </ul>
          </b-message>
        </template>
        <b-message 
          title="Khách hàng đặc biệt" 
          active 
          aria-close-label="Close message" 
          :closable="false" 
          class="special-customer-message"
          v-if="specialCustomerData.length"
        >
          Lý do:
          <ul>
            <li v-for="item in specialCustomerData" :key="item.typeErrId"> {{ '- ' + item.reason }}</li>
          </ul>
        </b-message>

        <b-message 
          title="Ưu đãi" 
          active 
          aria-close-label="Close message" 
          :closable="false" 
          class="special-customer-message"
          v-if="campaignData.length"
        >
          <ul>
            <li v-for="campaign in campaignData" :key="campaign.mktCampaign.id"> 
              <b>{{ campaign.mktCampaign.description }}</b>
              <ul :style="{marginBottom: '5px'}">
                <li v-for="service in campaign.lstService" :key="service.id">
                  {{ `- ${service.name} (Còn ${campaign.mktCampaign.maxUsage - service.timesUsed + 1} lần)`}}
                </li>
              </ul>
            </li>
          </ul>
        </b-message>
        
      </div>
      <div class="column">
        <div v-if="!assignedSkinner" style="color: red">Vui lòng chọn skinner ở trang <a target="_blank" :href="staffScheduleUrl">xếp gội</a></div>
        <StaffSelection 
          title="Skinner"
          placeholderText="Chọn skinner"
          :data="skinners"
          :selected="assignedSkinner"
          disabled
        />
        
        <!--<h4 class="has-text-left is-bold">Skinner</h4>
        <div class="assigned-skinner-container">
          <span v-if="assignedSkinner">{{ assignedSkinner.fullName }}</span>
        </div>-->

        <StaffSelection 
          title="Nhân viên checkin"
          placeholderText="Chọn checkin"
          :data="checkins"
          :selected="bill.selectedCheckin"
          @select="bill.selectedCheckin = $event"
        />

        <h4 class="has-text-left is-bold">Dịch vụ</h4>
        <b-field>
          <b-taginput
            v-model="bill.chosenServices"
            :data="filteredServices"
            autocomplete
            :allow-new="false"
            field="displayName"
            icon="label"
            placeholder="Chọn dịch vụ"
            @typing="getFilteredServices"
          ></b-taginput>
        </b-field>
        
        <h4 class="has-text-left is-bold" v-if="indexedProducts.length > 0">Sản phẩm</h4>
        <b-field v-if="indexedProducts.length > 0">
          <b-taginput
            v-model="bill.chosenProducts"
            :data="filteredProducts"
            autocomplete
            :allow-new="false"
            field="displayName"
            icon="label"
            placeholder="Chọn sản phẩm"
            @typing="getFilteredProducts"
            allow-duplicates
          ></b-taginput>
        </b-field>

        <div class="sm-sale-prompt" v-if="this.booking && this.booking.id && !booking.totalBill && !booking.isShineMember">Checkin giới thiệu chính sách KH Mới và tư vấn quyền lợi Shine Member</div>
        
        <div class="field">
          
          <button
            v-if="!isPrintingBill && booking && (booking.isMakeBill)"
            class="button is-large is-fullwidth is-primary is-uppercase"
            @click="updateBill"
            disabled
          ><!-- :disabled="tempCustomer.error && (tempCustomer.error.phone || tempCustomer.error.name)" -->
            In lại
            phiếu
          </button>
          <button
            v-else
            class="button is-large is-fullwidth is-primary is-uppercase"
            @click="createBill"
            disabled
            
          > <!-- :disabled="tempCustomer.error && (tempCustomer.error.phone || tempCustomer.error.name)" -->
            In
            phiếu
          </button>
          
        </div>
      </div>
    </div>
    <ModalCloseButton />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import apiConfig from "@/configs/api";
import axios from "axios";
import CustomerForm from "@/components/Timeline/CustomerForm";
import ModalCloseButton from "@/components/Shared/ModalCloseButton"


import notiUtil from "@/utils/noti";
import convertUtil from "@/utils/convert";

import linkConfig from '@/configs/link';

import StaffSelection from "@/components/Timeline/StaffSelection";

/*const sleeper = ms => {
  console.log('sleeper in action')
  return function(x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}*/

export default {
  name: "PrintBill",
  components: {
    CustomerForm,
    ModalCloseButton,
    StaffSelection
  },
  data() {
    return {
      filteredServices: [],
      filteredProducts: [],
      tempCustomer: {
        name: "",
        phone: "",
        error: {
          name: null,
          phone: null
        }
      },
      customer: {},
      bill: null,
      isPrintingBill: false,
      isExperimentSalon: true,
      createdBooking: null,
      staffScheduleUrl: linkConfig.STAFF_SCHEDULE_URL
    };
  },

  props: ["bookingId"],

  methods: {
    ...mapMutations(['updateBooking']),
    initBill() {
      let bill = this.booking && this.booking.id ? this.billByBookingId[this.booking.id] : null;
      
      if (!bill) bill = {
        isBookStylist: false,
        // selectedSkinner: this.getDetailSkinner(this.booking.skinnerId) || this.getTeamSkinnerByStylistId(this.stylist.id),
        selectedSkinner: {},
        selectedCheckin: this.getSelectedCheckin(),
        chosenServices: [],
        chosenProducts: [],
        isBookStylist: this.booking.isBookStylist
      };
      this.bill = bill;

      if(!this.bill.id) {

        if (this.booking.chosenServices) {
          const chosenServices = JSON.parse(this.booking.chosenServices);
          if (chosenServices.length) {
            this.bill.chosenServices = chosenServices
                                    .map(item => this.getIndexedServiceById(item.id))
                                    .filter(item => item != null)
          }
          
        }
      }
    },

    async book() {
      try {
        let oldCustomer = this.checkCustomer();

        let inputData = {
          customerPhone: this.customer.phone,
          salonId: this.selectedSalon.id,
          stylistId: this.stylist.id,
          hourId: this.hour.hourId,
          subHourId: this.hour.subHourId,
          isBookStylist: this.bill.isBookStylist,
          dateBook: this.$store.getters.selectedMomentDate.format('YYYY-MM-DD')
        };
        // return subHourId and secondSubHourId if subHourId contains secondSubHourId
        inputData = convertUtil.transformSubHourOut(inputData)

        let booking = null;
        if (oldCustomer && !oldCustomer.completeBillTime) {
          if (oldCustomer.isMakeBill && this.$store.getters.getHourIdFromSubHour(oldCustomer.subHourId) !== this.hour.hourId) {
            notiUtil.changeBookingDenyBillPrinted(this, oldCustomer.customerName);
            booking = null;
          } else {
            booking = await this.changeBookingTime(oldCustomer.id, oldCustomer.customerName, inputData);
          }
        } else {
          const { data } = await axios.post(`${apiConfig.MAIN_API}/api/booking`, inputData);
          if (data.message == "update") {
            booking = await this.changeBookingTime(data.booking.bookingId, data.booking.customerName, inputData);
          } else {
            booking = data;
            notiUtil.bookingSuccess(this, booking.customerName);

            if (!this.isTodaySelected)
              this.$store.dispatch("addBooking", booking);
          }
        }
        return booking;
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.message) error = error.response.data.message;
        notiUtil.generalError(this, error);
      }
    },

    getFilteredServices(text) {
      const normalizedText = convertUtil.removeVietnameseAccent(text.toLowerCase());
      this.filteredServices = this.indexedServices.filter(item => 
        item.normalizedName.indexOf(normalizedText) >= 0
      );
    },

    getFilteredProducts(text) {
      const normalizedText = convertUtil.removeVietnameseAccent(text.toLowerCase());
      this.filteredProducts = this.indexedProducts.filter(item => 
        item.normalizedName.indexOf(normalizedText) >= 0
      );
    },

    aggregateProducts(selectedProducts) {
      
      const count = {};
      selectedProducts.forEach(product => {
        const productId = product.id;
        if (!count[productId]) {
          count[productId] = product;
          count[productId].quantity = 1;
        } else count[productId].quantity++;
      })
      return Object.keys(count).map(key => count[key]);
    },

    async changeBookingTime(bookingId, customerName, inputData) {
      if (await notiUtil.confirmChangeBookingTime(this, customerName)) {
        const { data: booking } = await axios.put(`${apiConfig.MAIN_API}/api/booking?bookingId=${bookingId}`, inputData);
        notiUtil.changeBookingSuccess(this, customerName);

        if (!this.isTodaySelected)
          this.$store.dispatch("addBooking", booking);

        return booking;
      }
      return null;
    },

    async createBill() {
      let errorMessage = null;
      if (this.isPrintingBill) errorMessage = `Hệ thống đang in bill, vui lòng không click nhiều lần!`;
      if (this.booking && this.booking.id && this.shouldDeferAction[this.booking.id])
        errorMessage = `Thao tác quá nhanh, vui lòng thử lại sau!`
      if (this.isEditingCustomer) errorMessage = `Vui lòng lưu thông tin khách hàng trước khi thao tác.`;
      if (this.isExperimentSalon && !this.assignedSkinner) errorMessage = `Vui lòng chọn skinner cho bill`;

      if (errorMessage) {
        this.$buefy.snackbar.open({
          message: errorMessage,
          position: 'is-top'
        })
        return;
      }

      this.isPrintingBill = true;
      
      try {
        
        let serviceIds = [];
        for (let i = 0; i < this.bill.chosenServices.length; i++) {
          serviceIds.push(this.bill.chosenServices[i].id);
        }
        
        let bookingId = this.booking.id;
        if (!bookingId) {
          const booking = await this.book();
          
          if (booking) {
            bookingId = booking.id;
            if (bookingId) this.createdBooking = booking;
            let oldCustomer = this.checkCustomer();
            if (oldCustomer.isMakeBill && !oldCustomer.completeBillTime) {
              
              const res = await axios.put(`${apiConfig.MAIN_API}/api/bill-service?bookingId=${bookingId}&stylistId=${this.stylist.id}`);
              this.doPrintBill(res.data);
              return;
            }
          }
        }

        if (!bookingId) return;

        
        let data = {
          checkinId: 0,
          bookingId: bookingId,
          serviceIds: serviceIds.toString(),
          isBookStylist: this.bill.isBookStylist,
          skinnerId: 0
        };

        if (this.bill.chosenProducts.length) {
          data.inputProducts = this.aggregateProducts(this.bill.chosenProducts)
                                    .map(item => ({ id: item.id, quantity: item.quantity }))
        }
        
        if (this.bill.selectedCheckin) {
          data.checkinId = this.bill.selectedCheckin.id;
          localStorage['checkinId'] = data.checkinId;
        }
        if (this.isExperimentSalon && this.assignedSkinner) data.skinnerId = this.assignedSkinner.id;

        const res = await axios.post(`${apiConfig.MAIN_API}/api/bill-service`, data);
          
        this.booking.isMakeBill = true;
        this.booking.isCall = true;
        if(!this.booking.isCallTime) this.booking.isCallTime = Date.now();
        
        
        this.bill.id = res.data.id;
        this.bill.bookingId = bookingId;
        this.updateSavedBill(this.bill);
        
        this.doPrintBill(res.data);
        
        await axios.put(`${apiConfig.MAIN_API}/api/booking/update-iscall?bookingId=${bookingId}&isChecked=${true}`);
      } catch (error) {
        notiUtil.generalError(this, error);
      } finally {
        this.isPrintingBill = false;
      }
    },

    updateSavedBill(bill) {
      this.$store.commit("setBillMap", bill);
    },

    async updateBill() {
      
      if (this.booking.id) {
        await axios
          .put(
            `${apiConfig.MAIN_API}/api/bill-service?bookingId=${this.booking.id}&stylistId=${this.stylist.id}&skinnerId=${this.assignedSkinner.id}`
          )
          .then(res => {
            this.bill.id = res.data.id;
            this.doPrintBill(res.data);
            this.updateBooking({
              ...this.booking,
              skinnerId: this.assignedSkinner.id
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.updateSavedBill(this.bill);
    },
    getMemberName() {
        if( new Date(this.booking.memberEndTime) - new Date() > 0){
            return this.booking.memberCode === "SMS"
                      ? "Shine Member (Silver)"
                      : this.booking.memberCode === "SMB"
                      ? "Shine Member (Black)"
                      : "";
        }else{
          return "";
        }
    },
    async doPrintBill(responseData) {
      let temp = {
        createdTime: responseData.createdTime,
        customerName: this.booking.customerName, // responseData.customerName, 
        customerPhone: this.booking.customerPhone, // responseData.customerPhone,
        customerTagData: this.customerTagData,
        isBookStylist: this.bill.isBookStylist,
        hourFrame: this.hour.hour, 
        isCallTime: this.booking.isCallTime, 
        customerNote: this.booking.note || "", 
        systemNote: this.booking.note1 || "", 
        specialCustomerNote: this.booking.specialCusNote || "",
        pdfBillCode: responseData.pdfBillCode,
        stylistName: this.stylist.fullName,
        selectedProducts: this.aggregateProducts(this.bill.chosenProducts),
        selectedServices: this.bill.chosenServices,
        skinnerName: this.isExperimentSalon && this.assignedSkinner ? this.assignedSkinner.fullName : "",
        // skinnerName: "",
        checkinName: this.bill.selectedCheckin ? this.bill.selectedCheckin.fullName : "",
        isShineMember: this.booking.isShineMember,
        memberEndTime: this.booking.memberEndTime,
        totalBill: this.booking.totalBill,
        memberName: this.getMemberName(),
        specialCustomerData: this.specialCustomerData,
        campaignData: this.campaignData,
        billId: this.bill.id
      };
      const pdfUtil = await import("@/utils/pdf");
      
      pdfUtil.default.print(temp);
      this.$parent.close();
    },

    getCustomer(customer) {
      this.customer = customer;
      this.tempCustomer = customer;
      if (customer.phone && customer.name) {
        this.$store.dispatch('fetchSpecialCustomerData', customer.phone);
        this.$store.dispatch("fetchCustomerTagData", customer.phone);
        this.$store.dispatch("fetchCampaignData", { customerPhone: customer.phone, bookingId: 0 });
      }
    },

    checkCustomer() {
      let bookings = this.$store.state.booking.bookings;
      for (let i = 0; i < bookings.length; i++) {
        if (bookings[i].customerPhone === this.customer.phone) {
          return bookings[i];
        }
      }
      return false;
    }
  },

  computed: {
    ...mapGetters(['getTeamSkinnerByStylistId', 'skinners', 'checkins', 'selectedSalon', 'isTodaySelected', 'getIndexedServiceById', 'getSelectedCheckin', 'isEditingCustomer', 'indexedServices', 'indexedProducts', 'billByBookingId', 'billMap', 'shouldDeferAction', 'getSpecialCustomerData',"getCustomerTagData", 'getCampaignData', 'getDetailSkinner']),
    stylist() {
      return this.$store.getters.getDetailStylist(this.booking.stylistId);
    },

    assignedSkinner() {
      const assignedSkinner = this.getDetailSkinner(this.booking.skinnerId);
      return assignedSkinner;
    },

    hour() {
      return this.$store.getters.getDetailHour(this.booking.subHourId);
    },
    booking() {
      if (this.createdBooking) return this.createdBooking;
      if (typeof this.bookingId == 'string') return {
        stylistId: parseInt(this.bookingId.split('-')[0]),
        subHourId: this.bookingId.split('-')[1]
      }
      return this.$store.getters.bookingMap[this.bookingId];
    },
    specialCustomerData() {
      let phone = this.booking.customerPhone || this.customer.phone;
      return this.getSpecialCustomerData(phone) || [];
    },
    customerTagData() {
      let phone = this.booking.customerPhone || this.customer.phone;
      return this.getCustomerTagData(phone) || [];
    },
    campaignData() {
      let phone = this.booking.customerPhone || this.customer.phone;
      let campaigns = this.getCampaignData(phone) || [];
      campaigns = campaigns.map(campaign => {
        campaign.lstService = campaign.lstService.map(service => ({
          ...service,
          name: this.getIndexedServiceById(service.serviceId).serviceName
        }))
        return campaign;
      })
      console.log(campaigns);
      return campaigns;
    }
  }, 

  watch: {
    "bill.isBookStylist": function(value, oldValue) {
      if (oldValue !== undefined) {
        this.$store.dispatch('updateIsBookStylist', { bookingId: this.bookingId, isBookStylist: value });
      }
    }
  },

  async created() {
    console.log('staffScheduleUrl', this.staffScheduleUrl)
    if (this.booking && this.booking.id) {
      this.customer = {
        customerName: this.booking.customerName,
        phone: this.booking.customerPhone
      };
      
      this.tempCustomer.name = this.booking.customerName;
      this.tempCustomer.phone = this.booking.customerPhone;
      this.$store.dispatch("fetchCustomerTagData", this.booking.customerPhone);
      this.$store.dispatch("fetchCampaignData", { customerPhone: this.booking.customerPhone, bookingId: this.booking.id || 0 });
      if (this.booking.isSpecialCus) {
        this.$store.dispatch('fetchSpecialCustomerData', this.booking.customerPhone);
      }
    }

    this.initBill();
    // this.isExperimentSalon = this.$store.getters.isExperimentSalon(this.selectedSalon.id);
    
  }
};
</script>

<style scoped>
.print-bill {
    padding: 4rem 2rem;
  }
.stylist-text {
  font-size: 18px;
}

.print-bill ::-webkit-input-placeholder {
  color: #616161
}

.input {
  color: #000;
}

.special-customer-message {
  margin-top: 10px;
}

.sm-sale-prompt {
  text-transform: uppercase;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.assigned-skinner-container {
  height: 50px;
  margin-bottom: 10px;
}
</style>
