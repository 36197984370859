import { render, staticRenderFns } from "./BookingModal.vue?vue&type=template&id=f32fa512&scoped=true&"
import script from "./BookingModal.vue?vue&type=script&lang=js&"
export * from "./BookingModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f32fa512",
  null
  
)

export default component.exports