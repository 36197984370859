<template>
    <div class="selection-container">
        <h4 class="has-text-left is-bold">{{ title }}</h4>
        <b-field>
          <b-autocomplete
            size="is-small"
            :open-on-focus="true"
            v-model="inputName"
            :placeholder="placeholderText"
            :data="filteredData"
            field="displayName"
            @select="$emit('select', $event)"
            @focus="inputName = ''; $emit('select', null)"
            :disabled="disabled"
          >
          </b-autocomplete>
        </b-field>
    </div>
</template>

<script>

import convertUtil from "@/utils/convert";

export default {
    name: "StaffSelection",
    props: {
        selected: {
            type: Object,
            default: null
        },
        data: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: ""
        },
        placeholderText: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputName: ""
        }
    },
    methods: {
        /*onSelect(selected) {
            this.$emit('select', selected);
            this.inputName = (selected && selected.id) ? `${selected.fullName} (${selected.id})` : "";
        }*/
    },
    created() {
        this.indexedStaff = this.data.map(item => ({
            ...item,
            normalizedName: convertUtil.removeVietnameseAccent(item.fullName.toLowerCase()) + ' ' + item.id,
            displayName: `${item.fullName} (${item.id})`
        }))
        
        if (this.selected && this.selected.id) this.inputName = this.selected.fullName + ' (' + this.selected.id + ')';
    },
    computed: {
        filteredData() {
            return this.indexedStaff.filter(item =>
                item.normalizedName
                    .indexOf(convertUtil.removeVietnameseAccent(this.inputName.toLowerCase())) >= 0
            );
        }
    },
    watch: {
        selected: {
            deep: true,
            handler() {
                if (this.selected && this.selected.id) this.inputName = this.selected.fullName + ' (' + this.selected.id + ')';
            }
        }
    }
    
}
</script>

<style scoped>
.selection-container {
    margin-bottom: 15px;
}
</style>
